// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".UserSettings-module__1rF4PUjiH6Pq5hVcAt1RW2 {\n  width: 860px; /* wide enough so that all tabs fit in */\n}\n\n.UserSettings-module__1rF4PUjiH6Pq5hVcAt1RW2.UserSettings-module__5mOdkll7Y0HxyiRb5Jcia {\n  --spacing: 10px;\n\n  width: calc(100% - var(--spacing) * 2); /* allow lateral spacing */\n  max-width: 1100px;\n}\n", "",{"version":3,"sources":["UserSettings.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAE,wCAAwC;AACxD;;AAEA;EACE,eAAe;;EAEf,sCAAsC,EAAE,0BAA0B;EAClE,iBAAiB;AACnB","file":"UserSettings.module.css","sourcesContent":[".modal {\n  width: 860px; /* wide enough so that all tabs fit in */\n}\n\n.modal.modal-wide {\n  --spacing: 10px;\n\n  width: calc(100% - var(--spacing) * 2); /* allow lateral spacing */\n  max-width: 1100px;\n}\n"]}]);
// Exports
exports.locals = {
	"modal": "UserSettings-module__1rF4PUjiH6Pq5hVcAt1RW2",
	"modal-wide": "UserSettings-module__5mOdkll7Y0HxyiRb5Jcia"
};
module.exports = exports;
