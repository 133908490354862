// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".WrongTeamStub-module__1OGep7XAr-jBvVwEz0fbwf {\n  margin: 50px auto;\n  text-align: center;\n  width: 400px;\n}\n\n.WrongTeamStub-module___H8Rq8MbKVk8J2oGyYGva {\n  color: var(--warning-text-color);\n}\n\n.WrongTeamStub-module__2-ZoCG6M0Hw9Je6sjuPoNh {\n  color: white;\n  margin-right: 4px;\n  padding-top: 6px;\n}\n\n.WrongTeamStub-module__22wAD7AJ-ONeFCZ7bYmHpt {\n  margin-top: 32px;\n}\n", "",{"version":3,"sources":["WrongTeamStub.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","file":"WrongTeamStub.module.css","sourcesContent":[".not-found {\n  margin: 50px auto;\n  text-align: center;\n  width: 400px;\n}\n\n.error-code {\n  color: var(--warning-text-color);\n}\n\n.change-team-icon {\n  color: white;\n  margin-right: 4px;\n  padding-top: 6px;\n}\n\n.return-to-list {\n  margin-top: 32px;\n}\n"]}]);
// Exports
exports.locals = {
	"not-found": "WrongTeamStub-module__1OGep7XAr-jBvVwEz0fbwf",
	"error-code": "WrongTeamStub-module___H8Rq8MbKVk8J2oGyYGva",
	"change-team-icon": "WrongTeamStub-module__2-ZoCG6M0Hw9Je6sjuPoNh",
	"return-to-list": "WrongTeamStub-module__22wAD7AJ-ONeFCZ7bYmHpt"
};
module.exports = exports;
