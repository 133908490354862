// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Autoresolve-module__2_IfIXU4k2SukyLA7qaSX- {\n  margin-bottom: 32px;\n  padding-bottom: 32px;\n  border-bottom: var(--border);\n}\n\n.Autoresolve-module__2MJsHhlCYx0q7mIlOhai3k ul {\n  margin-left: 24px;\n}\n\n.Autoresolve-module__184K7TEmDPOHWsJYdlOcXs {\n  display: grid;\n}\n\n.Autoresolve-module__1E2g-ooEJc06jwfGH8Kwap {\n  width: 520px;\n}\n\n.Autoresolve-module__3sTp3K_clBSe8fqUCO0gc9 {\n  margin-top: 24px;\n}\n\n.Autoresolve-module__1vSxHMRVu1ej1oZbnm58Q3 {\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.Autoresolve-module__1vSxHMRVu1ej1oZbnm58Q3 .Autoresolve-module__2JaFq6jbsTTV6H3FZczu0Q {\n  margin-left: 8px;\n}\n\n.Autoresolve-module__1GYlyDyPDwzvEbjJ7Kj9lb {\n  height: 32px;\n  padding: 4px 8px;\n  margin-top: 8px;\n  min-width: 500px;\n  width: 520px;\n}\n\n.Autoresolve-module__2gJcuf8IAU1_tqx2jCRkBj {\n  color: var(--warning-text-color);\n}\n", "",{"version":3,"sources":["Autoresolve.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,4BAA4B;AAC9B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,gCAAgC;AAClC","file":"Autoresolve.module.css","sourcesContent":[".border-container {\n  margin-bottom: 32px;\n  padding-bottom: 32px;\n  border-bottom: var(--border);\n}\n\n.root ul {\n  margin-left: 24px;\n}\n\n.settings-label {\n  display: grid;\n}\n\n.team-select {\n  width: 520px;\n}\n\n.team-select-actionbuttons {\n  margin-top: 24px;\n}\n\n.confirmation-buttons {\n  display: flex;\n  flex-direction: row-reverse;\n}\n\n.confirmation-buttons .save-team-button {\n  margin-left: 8px;\n}\n\n.autoresolve-block {\n  height: 32px;\n  padding: 4px 8px;\n  margin-top: 8px;\n  min-width: 500px;\n  width: 520px;\n}\n\n.warning-icon-color {\n  color: var(--warning-text-color);\n}\n"]}]);
// Exports
exports.locals = {
	"border-container": "Autoresolve-module__2_IfIXU4k2SukyLA7qaSX-",
	"root": "Autoresolve-module__2MJsHhlCYx0q7mIlOhai3k",
	"settings-label": "Autoresolve-module__184K7TEmDPOHWsJYdlOcXs",
	"team-select": "Autoresolve-module__1E2g-ooEJc06jwfGH8Kwap",
	"team-select-actionbuttons": "Autoresolve-module__3sTp3K_clBSe8fqUCO0gc9",
	"confirmation-buttons": "Autoresolve-module__1vSxHMRVu1ej1oZbnm58Q3",
	"save-team-button": "Autoresolve-module__2JaFq6jbsTTV6H3FZczu0Q",
	"autoresolve-block": "Autoresolve-module__1GYlyDyPDwzvEbjJ7Kj9lb",
	"warning-icon-color": "Autoresolve-module__2gJcuf8IAU1_tqx2jCRkBj"
};
module.exports = exports;
