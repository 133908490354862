// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".EscalationChainCard-module__23PM8-xmLTqv2MTNr3lKCr {\n  display: block;\n}\n\n.EscalationChainCard-module__1d2iVDlfBnTM48jqyGaYr8 {\n  padding: 2px 4px;\n  background: rgba(27, 133, 94, 0.15);\n  border: 1px solid var(--success-text-color);\n  border-radius: 2px;\n}\n\n.EscalationChainCard-module__nVBBqMqUiHPnNHtEVHVh6 {\n  color: var(--success-text-color);\n}\n", "",{"version":3,"sources":["EscalationChainCard.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,mCAAmC;EACnC,2CAA2C;EAC3C,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;AAClC","file":"EscalationChainCard.module.css","sourcesContent":[".root {\n  display: block;\n}\n\n.connected-integrations {\n  padding: 2px 4px;\n  background: rgba(27, 133, 94, 0.15);\n  border: 1px solid var(--success-text-color);\n  border-radius: 2px;\n}\n\n.icon {\n  color: var(--success-text-color);\n}\n"]}]);
// Exports
exports.locals = {
	"root": "EscalationChainCard-module__23PM8-xmLTqv2MTNr3lKCr",
	"connected-integrations": "EscalationChainCard-module__1d2iVDlfBnTM48jqyGaYr8",
	"icon": "EscalationChainCard-module__nVBBqMqUiHPnNHtEVHVh6"
};
module.exports = exports;
