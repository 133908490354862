// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Incidents-module__1_EW6-bUWPoDW85W4DdgYK {\n  margin-top: 24px;\n}\n\n.Incidents-module__39YjFks52kPyIqD5dDLUVz {\n  width: 400px;\n}\n\n.Incidents-module__1iD30OtGnSehzYLlxP6VL_ {\n  width: 100%;\n  justify-content: flex-end;\n}\n\n.Incidents-module__2YPnLVRbCh8ekjv64Er73P {\n  margin-bottom: 20px;\n}\n\n.Incidents-module__2JE7VpYLEy2xvVNXYUo1lL {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.Incidents-module__19wR2r1BgGD8cMrBI3fHN2 {\n  margin: 10px 0 10px 0;\n}\n\n.Incidents-module__1Rc-SPuHbyGRAmrcp5yZ6C {\n  color: var(--secondary-text-color);\n}\n\n.Incidents-module__2p71pH-wzfs3Qd9lZ4GvcD {\n  font-size: 12px;\n  height: 24px;\n  margin-right: 0;\n}\n\n.Incidents-module__2kdhlFGpCa1_y7Tjrapvlt {\n  width: 100%;\n  margin-top: 20px;\n}\n", "",{"version":3,"sources":["Incidents.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB","file":"Incidents.module.css","sourcesContent":[".root {\n  margin-top: 24px;\n}\n\n.select {\n  width: 400px;\n}\n\n.action-buttons {\n  width: 100%;\n  justify-content: flex-end;\n}\n\n.filters {\n  margin-bottom: 20px;\n}\n\n.above-incidents-table {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.bulk-actions {\n  margin: 10px 0 10px 0;\n}\n\n.other-users {\n  color: var(--secondary-text-color);\n}\n\n.silence-button-inline {\n  font-size: 12px;\n  height: 24px;\n  margin-right: 0;\n}\n\n.pagination {\n  width: 100%;\n  margin-top: 20px;\n}\n"]}]);
// Exports
exports.locals = {
	"root": "Incidents-module__1_EW6-bUWPoDW85W4DdgYK",
	"select": "Incidents-module__39YjFks52kPyIqD5dDLUVz",
	"action-buttons": "Incidents-module__1iD30OtGnSehzYLlxP6VL_",
	"filters": "Incidents-module__2YPnLVRbCh8ekjv64Er73P",
	"above-incidents-table": "Incidents-module__2JE7VpYLEy2xvVNXYUo1lL",
	"bulk-actions": "Incidents-module__19wR2r1BgGD8cMrBI3fHN2",
	"other-users": "Incidents-module__1Rc-SPuHbyGRAmrcp5yZ6C",
	"silence-button-inline": "Incidents-module__2p71pH-wzfs3Qd9lZ4GvcD",
	"pagination": "Incidents-module__2kdhlFGpCa1_y7Tjrapvlt"
};
module.exports = exports;
